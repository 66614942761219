@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat&display=swap');


h1, h2, h3, h4, h5 {
  font-family: Lato, Source Sans Pro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  font-weight: 700;
}

.ant-modal-body {
  h1, h2, h3, h4, h5, label {
    color: #E74E35;
  }
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  .ant-menu {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.ant-drawer-body {
  .ant-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 16px;
  }
}

.ant-table {
  box-shadow: 0px 0px 16px #0016282b;
}

.ant-table-content {
  overflow-x: scroll;
}

.ant-layout {
  background: unset;
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
                        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
                        'Noto Color Emoji';@font-size-base: 13px;@heading-color: #f7af84;@text-color: #333333;@text-color-secondary: #444444;@primary-color: #E74E35;@padding-lg: 24px;@padding-md: 16px;@padding-sm: 12px;@padding-xs: 8px;@padding-xss: 4px;@height-base: 48px;@height-lg: 40px;@height-sm: 24px;@margin-lg: 24px;@margin-md: 16px;@margin-sm: 12px;@margin-xs: 8px;@margin-xss: 4px;@form-item-label-colon-margin-right: 8px;@form-item-label-colon-margin-left: 2px;